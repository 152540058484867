import Api from "../../api/Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ADD_APPLESMS_FAIL,
  ADD_APPLESMS_SUCCESS,
  DELETE_APPLESMS_SUCCESS,
  GET_APPLESMS,
  GET_APPLESMS_SUCCESS,
  UPDATE_APPLESMS_SUCCESS,
} from "./actionTypes";

export const loadAppleSMS = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.get("/applesmsall/get");

  toast.promise(req, {
    pending: "Getting AppleSMS IDs...",
    success: {
      render({ data }) {
        dispatch({
          payload: { applesms: data.data.data, defective: data.data.defective },
          type: GET_APPLESMS_SUCCESS,
        });
        return "Your Data Is Ready";
      },
    },
    error: {
      render({ data }) {
        return "An error occurred while getting applesmss IDs";
      },
    },
  });
};

export const addAppleSMS = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/applesms/add", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: ADD_APPLESMS_SUCCESS,
        });
        return "AppleSMS ID added successfully";
      },
    },
    error: {
      render({ data }) {
        // return data?.data.response.data?.message;

        return data?.response.data?.message;
      },
    },
  });
};

export const updateAppleSMS = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/applesms/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_APPLESMS_SUCCESS,
        });
        return "AppleSMS ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your AppleSMS ID";
      },
    },
  });
};

export const deleteAppleSMS = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/applesms/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: DELETE_APPLESMS_SUCCESS,
        });
        return "AppleSMS ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your AppleSMS ID";
      },
    },
  });
};

export const soldAppleSMS = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/applesms/sold", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_APPLESMS_SUCCESS,
        });
        return "AppleSMS ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your AppleSMS ID";
      },
    },
  });
};

export const importAppleSMS = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/applesms/import", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadAppleSMS());
        return "AppleSMS IDs imported successfully";
      },
    },
    error: {
      render({ data }) {
        return data?.response.data?.message;
      },
    },
  });
};

export const exportAppleSMS = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/applesms/export", { number: data.formData.number });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadAppleSMS());

        window.open(response.data.data, "_blank");
        return "AppleSMS IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportAppleSMSQr = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/applesms/export/qr", {
    number: data.number,
    image: data.image,
    shownote: data.shownote,
    note: data.note,
  });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadAppleSMS());

        window.open(response.data.data, "_blank");
        return "AppleSMS IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const fixApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/applesms/fix", { accounts: data.formData.accounts });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadAppleSMS());

        // window.open(response.data.data, "_blank");
        return "Apple IDs fixed successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const deleteAppleDefective = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/applesms/delete/defective", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadAppleSMS());
        return "Apple ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your Apple ID";
      },
    },
  });
};
