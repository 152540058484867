import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";

import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";

import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";

import AppleID from "../Pages/Mega/AppleId.js";
import AppleQ from "../Pages/Applesq/AppleId.js";
import NfcCard from "../Pages/Mega/NfcCard.js";
import Public from "../Pages/Authentication/public.js";
import Exports from "../Pages/Mega/Exports.js";
import Publicq from "../Pages/Authentication/publicq.js";
import Googleid from "../Pages/Googleid/Googleid";
import ApplegDefectives from "../Pages/Googleid/ApplegDefectives.js";
import AppleDefectives from "../Pages/Mega/AppleDefectives.js";
import AppleqDefectives from "../Pages/Applesq/AppleqDefectives.js";
import Publicg from "../Pages/Authentication/publicg.js";
import AppleSMS from "../Pages/AppleSMS/AppleSMS.js";
import AppleSMSDefectives from "../Pages/AppleSMS/AppleSMSDefectives";
import Publicsms from "../Pages/Authentication/publicsms.js";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/apple-id", component: <AppleID /> },
  { path: "/apple-defectives", component: <AppleDefectives /> },
  { path: "/apple-q", component: <AppleQ /> },
  { path: "/appleq-defectives", component: <AppleqDefectives /> },
  { path: "/exports", component: <Exports /> },
  { path: "/nfc-card", component: <NfcCard /> },
  { path: "/google", component: <Googleid /> },
  { path: "/google-defectives", component: <ApplegDefectives /> },

  { path: "/applesms", component: <AppleSMS /> },
  { path: "/applesms-defectives", component: <AppleSMSDefectives /> },
  // Calender
  // { path: "/calendar", component: <Calender /> },

  // Profile
  { path: "/settings", component: <UserProfile /> },

  // E-mail
  // { path: "/inbox", component: <Inbox /> },
  // { path: "/read-email", component: <ReadEmail /> },
  // { path: "/compose-email", component: <EmailCompose /> },

  // Utility Pages
  // { path: "/pages-starter", component: <StarterPage /> },
  // { path: "/pages-timeline", component: <TimeLine /> },
  // { path: "/pages-faqs", component: <FAQs /> },
  // { path: "/pages-pricing", component: <Pricing /> },

  // UiElements Pages
  // { path: "/ui-alerts", component: <UiAlerts /> },
  // { path: "/ui-badge", component: <UiBadge /> },
  // { path: "/ui-breadcrumb", component: <UiBreadcrumb /> },
  // { path: "/ui-buttons", component: <UiButtons /> },
  // { path: "/ui-cards", component: <UiCards /> },
  // { path: "/ui-carousel", component: <UiCarousel /> },
  // { path: "/ui-dropdowns", component: <UiDropdown /> },
  // { path: "/ui-grid", component: <UiGrid /> },
  // { path: "/ui-images", component: <UiImages /> },
  // { path: "/ui-lightbox", component: <UiLightbox /> },
  // { path: "/ui-modals", component: <UiModals /> },
  // { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  // { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  // { path: "/ui-sessiontimeout", component: <UiSessionTimeout /> },
  // { path: "/ui-pagination", component: <UiPagination /> },
  // { path: "/ui-progressbars", component: <UiProgressBars /> },
  // { path: "/ui-placeholders", component: <UiPlaceholders /> },
  // { path: "/ui-tabs-accordions", component: <UiTabs /> },
  // { path: "/ui-typography", component: <UiTypography /> },
  // { path: "/ui-toasts", component: <UiToasts /> },
  // { path: "/ui-video", component: <UiVideo /> },
  // { path: "/ui-popovers", component: <UiPopovers /> },
  // { path: "/ui-rating", component: <UiRating /> },

  // // Forms pages
  // { path: "/form-elements", component: <FormElements /> },
  // { path: "/form-validation", component: <FormValidations /> },
  // { path: "/form-advanced", component: <FormAdvanced /> },
  // { path: "/form-editor", component: <FormEditors /> },
  // { path: "/form-uploads", component: <FormUpload /> },
  // { path: "/form-editors", component: <FormXeditable /> },
  // { path: "/form-wizard", component: <FormWizard /> },
  // { path: "/form-mask", component: <FormMask /> },

  // // Tables pages
  // { path: "/tables-basic", component: <BasicTable /> },
  // { path: "/tables-listjs", component: <ListJs /> },
  // { path: "/table-datatables", component: <DataTable /> },

  // // Charts Pages
  // { path: "/chart-apexcharts", component: <ApexCharts /> },
  // { path: "/chart-chartjscharts", component: <ChartJs /> },
  // { path: "/chart-floatcharts", component: <FloatChart /> },
  // { path: "/chart-jknobcharts", component: <JknobCharts /> },
  // { path: "/chart-sparklinecharts", component: <Sparklinechart /> },

  // // Icons Pages
  // { path: "/icon-boxicon", component: <IconBoxicons /> },
  // { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  // { path: "/icons-fontawesome", component: <IconFontawesome /> },
  // { path: "/icon-dripicons", component: <IconDripicons /> },

  // // Maps Pages
  // { path: "/maps-vector", component: <VectorMaps /> },
  // { path: "/maps-google", component: <GoogleMap /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Authentication Inner Pages
  // { path: "/auth-login", component: <Login1 /> },
  // { path: "/auth-register", component: <Register1 /> },
  // { path: "/auth-recoverpw", component: <RecoverPassword /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },

  { path: "/public/:url", component: <Public /> },
  { path: "/publicq/:url", component: <Publicq /> },
  { path: "/google/:url", component: <Publicg /> },
  { path: "/applesms/:url", component: <Publicsms /> },
];

export { authProtectedRoutes, publicRoutes };
